<template>
	<div class="zui-bg-white">
		<!-- 文字条 -->
		<div class="top_tap flex_row  zui-relative">
			{{ detail.title }}
			<div class="btnblock header_r padding-0 col-md-1  col-sm-2 col-xs-3">
				<div v-if="!isLogin" class="zui-h-100 zui-pointer zui-flex zui-center-center log" style="font-size: 12px;">{{$t('login.login')}}</div>
				<div class="btnCenter">
					<span @click="_back" v-show="!isPhone" class="jinBtn cursor  zui-text-white back">{{$t('common.back')}}</span>
				</div>
			</div>
		</div>
		<!-- 返回按钮 -->
		<div id="back" v-show="isPhone" @click="_back"
			style="z-index:999; position: fixed;top: 50%;left: 0;width: 50px;height: 50px;border-radius: 50%;">
			<img src="../assets/image/back_phone.png" style="height: 100%;width: 100%;">
		</div>
		<!-- <pdf v-if="pdfPath" :src="pdfPath"></pdf> -->
		<!-- 主内容 -->
		<div class="airplane_page" id="pdfDom">
			<div class="box">
				<el-carousel indicator-position="outside" :interval="4000" class="carouselBox zui-p-t-20"
					:height="bannerHeight + 'px'">
					<el-carousel-item class="zui-rounded zui-overflow-hidden" v-for="(item, index) in detail.imgs"
						:key="index" style="width: 100%;height: 100%;">
						<el-image class="plane-img zui-border-radius-small" :src="item|swiperImg" fit="fill">
						</el-image>
					</el-carousel-item>
				</el-carousel>
			</div>

			<div class="center box">
				<el-row :gutter="10" style="margin:30px 0" type="flex">

					<el-col class="zui-text-center">
						<div class="jinBtn1" v-if="isShow" @click="getPdfEvn()">{{$t('airplaneDetail.mesg2')}}</div>
						<!-- <img v-if="isShow" @click="getPdfEvn()" class="pdf zui-pointer" src="../assets/image/13.png" alt="" /> -->
						<div class="grid-content title bg-purple  zui-text-center ">{{ $t('airplaneDetail.title1') }}
						</div>
					</el-col>

				</el-row>

				<el-row :gutter="10">
					<el-col :xs="12" :sm="6" :md="6">
						<div class="grid-content bg-purple-light  text ">
							<p class="text-justify">{{ $t('airplaneDetail.label1') }}</p>
						</div>
					</el-col>
					<el-col :xs="12" :sm="6" :md="6">
						<div class="grid-content bg-purple-light  text">
							<p class="text-right fixed_width">
								---&nbsp;&nbsp;<span
									class="text-left">{{ detail.flyTime }}{{ $t('airplaneDetail.unit1') }}</span>
							</p>
						</div>
					</el-col>
					<el-col :xs="12" :sm="6" :md="6">
						<div class="grid-content bg-purple-light   text">
							<p class="text-justify text-center">
								{{ $t('airplaneDetail.label2') }}
							</p>
						</div>
					</el-col>
					<el-col :xs="12" :sm="6" :md="6">
						<div class="grid-content bg-purple-light zui-text-right text">
							---&nbsp;&nbsp;<span
								class="text-left">{{ detail.landtime }}{{ $t('airplaneDetail.unit2') }}</span>

						</div>
					</el-col>
				</el-row>
				<el-row :gutter="10">
					<el-col :xs="12" :sm="6" :md="6">
						<div class="grid-content bg-purple-light  zui-text-center  text">
							<p class="text-justify ">
								{{ $t('airplaneDetail.label3') }}
							</p>
						</div>
					</el-col>
					<el-col :xs="12" :sm="6" :md="6">
						<div class="grid-content bg-purple-light   text">
							<p class="text-right fixed_width">
								---&nbsp;&nbsp;<span class="text-left">{{ detail.useTime }}
								</span>
							</p>
						</div>
					</el-col>
					<el-col :xs="12" :sm="6" :md="6">
						<div class="grid-content bg-purple-light  zui-text-center text">
							<p class="text-justify text-center">
								{{ $t('airplaneDetail.label4') }}
							</p>
						</div>
					</el-col>
					<el-col :xs="12" :sm="6" :md="6">
						<div class="grid-content bg-purple-light  zui-text-right  text">

							---&nbsp;&nbsp;<span class="text-left">{{ detail.seatNum }}{{ $t('airplaneDetail.unit5') }}
							</span>

						</div>
					</el-col>
				</el-row>
				<div class="xvxv"></div>
				<el-row :gutter="10">
					<el-col :xs="12" :sm="6" :md="6">
						<div class="grid-content bg-purple-light  zui-text-center text">
							<p class="text-justify">{{ $t('airplaneDetail.label5') }}</p>
						</div>
					</el-col>
					<el-col :xs="12" :sm="6" :md="6">
						<div class="grid-content bg-purple-light   text">
							<p class="text-right fixed_width">
								---&nbsp;&nbsp;<span
									class="text-left">{{ detail.planeHeight }}{{ $t('airplaneDetail.unit3') }}</span>
							</p>
						</div>
					</el-col>
					<el-col :xs="12" :sm="6" :md="6">
						<div class="grid-content bg-purple-light  zui-text-center text">
							<p class="text-justify text-center">{{ $t('airplaneDetail.label6') }}</p>
						</div>
					</el-col>
					<el-col :xs="12" :sm="6" :md="6">
						<div class="grid-content bg-purple-light  zui-text-right text">

							---&nbsp;&nbsp;<span
								class="text-left">{{ detail.planeLength }}{{ $t('airplaneDetail.unit3') }}
							</span>

						</div>
					</el-col>
				</el-row>
				<el-row :gutter="10">
					<el-col :xs="12" :sm="6" :md="6">
						<div class="grid-content bg-purple-light zui-text-center text">
							<p class="text-justify">{{ $t('airplaneDetail.label7') }}</p>
						</div>
					</el-col>
					<el-col :xs="12" :sm="6" :md="6">
						<div class="grid-content bg-purple-light   text">
							<p class="text-right fixed_width">
								---&nbsp;&nbsp;<span
									class="text-left">{{ detail.length }}{{ $t('airplaneDetail.unit3') }}</span>
							</p>
						</div>
					</el-col>
					<el-col :xs="12" :sm="6" :md="6">
						<div class="grid-content bg-purple-light  zui-text-center text">
							<p class="text-justify text-center">{{ $t('airplaneDetail.label8') }}</p>
						</div>
					</el-col>
					<el-col :xs="12" :sm="6" :md="6">
						<div class="grid-content bg-purple-light zui-text-right  text">
							---&nbsp;&nbsp;<span
								class="text-left">{{ detail.height }}{{ $t('airplaneDetail.unit3') }}</span>

						</div>
					</el-col>
				</el-row>
				<div class="xvxv"></div>
				<el-row :gutter="10">
					<el-col :xs="12" :sm="6" :md="6">
						<div class="grid-content bg-purple-light zui-text-center text">
							<p class="text-justify">{{ $t('airplaneDetail.label9') }}</p>
						</div>
					</el-col>
					<el-col :xs="12" :sm="6" :md="6">
						<div class="grid-content bg-purple-light  text">
							<p class="text-right fixed_width">
								---&nbsp;&nbsp;<span
									class="text-left">{{ detail.distance }}{{ $t('airplaneDetail.unit6') }}</span>
							</p>
						</div>
					</el-col>
					<el-col :xs="12" :sm="6" :md="6">
						<div class="grid-content bg-purple-light zui-text-center text">
							<p class="text-justify text-center">{{ $t('airplaneDetail.label10') }}</p>
						</div>
					</el-col>
					<el-col :xs="12" :sm="6" :md="6">
						<div class="grid-content bg-purple-lighttext zui-text-right  text">

							---&nbsp;&nbsp;<span
								class="text-left">{{ detail.cruiseMaxSpeed }}{{ $t('airplaneDetail.unit4') }}</span>

						</div>
					</el-col>
				</el-row>
				<el-row :gutter="10">
					<el-col :xs="12" :sm="6" :md="6">
						<div class="grid-content bg-purple-light zui-text-center text">
							<p class="text-justify">{{ $t('airplaneDetail.label11') }}</p>
						</div>
					</el-col>
					<el-col :xs="12" :sm="6" :md="6">
						<div class="grid-content bg-purple-light  text">
							<p class="text-right fixed_width">
								---&nbsp;&nbsp;<span
									class="text-left">{{ detail.cruiseHeight }}{{ $t('airplaneDetail.unit3') }}</span>
							</p>
						</div>
					</el-col>
					<el-col :xs="12" :sm="6" :md="6">
						<div class="grid-content bg-purple-light zui-text-center text">
							<p class="text-justify text-center">{{ $t('airplaneDetail.label18') }}</p>
						</div>
					</el-col>
					<el-col :xs="12" :sm="6" :md="6">
						<div class="grid-content bg-purple-light zui-text-right text">

							---&nbsp;&nbsp;<span
								class="text-left">{{ detail.cruiseSpeed }}{{ $t('airplaneDetail.unit4') }}</span>

						</div>
					</el-col>
				</el-row>
				<el-row :gutter="10">
					<!-- 发动机 标题-->
					<el-col>
						<div class="grid-content bg-purple-light"></div>
					</el-col>
					<el-col>
						<div class="grid-content title bg-purple  zui-text-center">{{ $t('airplaneDetail.title2') }}
						</div>
					</el-col>

					<el-col>
						<div class="grid-content bg-purple-light"></div>
					</el-col>
				</el-row>

				<el-row :gutter="10">
					<!-- 发动机-表头 -->

					<el-col :xs="8" :sm="8" :md="8">
						<div class="grid-content bg-purple-light zui-text-center text">
							<p class="text-justify  ">{{ $t('airplaneDetail.label12') }}</p>
						</div>
					</el-col>
					<el-col :xs="8" :sm="8" :md="8">
						<div class="grid-content bg-purple-light zui-text-center important-center text">

							{{ $t('airplaneDetail.label13') }}
						</div>
					</el-col>
					<el-col :xs="8" :sm="8" :md="8">
						<div class="grid-content bg-purple-light zui-text-right text">

							{{ $t('airplaneDetail.label16') }}
						</div>
					</el-col>
				</el-row>
		
		<el-row :gutter="10" v-for="item in enginesList" :key="item">
			<!-- 发动机-表头 -->
		
			<el-col :xs="8" :sm="8" :md="8">
				<div class="grid-content bg-purple-light zui-text-center text">
					<p class="text-justify  ">{{ item.enginemodel }}</p>
				</div>
			</el-col>
			<el-col :xs="8" :sm="8" :md="8">
				<div class="grid-content bg-purple-light zui-text-center important-center text">
	
					---&nbsp;&nbsp;{{ item.enginehours }}{{ $t('airplaneDetail.unit1') }}
				</div>
			</el-col>
			<el-col :xs="8" :sm="8" :md="8">
				<div class="grid-content bg-purple-light zui-text-right text">
		
					---&nbsp;&nbsp;{{ item.enginecirculatenum }}{{ $t('airplaneDetail.unit2') }}
				</div>
			</el-col>
		</el-row>
		
				<div class="xvxv"></div>
				<!-- 辅助动力 -->
				<el-row :gutter="10">
					<el-col>
						<div class="grid-content bg-purple-light"></div>
					</el-col>
					<el-col>
						<div class="grid-content title bg-purple  zui-text-center">{{ $t('airplaneDetail.title3') }}
						</div>
					</el-col>
					<el-col>
						<div class="grid-content bg-purple-light"></div>
					</el-col>
				</el-row>
				<el-row :gutter="10">
					<el-col :xs="6" :sm="4" :md="4">
						<div class="grid-content bg-purple-light  text zui-text-center">
							<p class="text-justify ">{{ $t('airplaneDetail.label14') }}</p>
						</div>
					</el-col>
					<el-col :xs="2" :sm="4" :md="4">
						<div class="grid-content bg-purple-light text"></div>
					</el-col>
					<el-col :xs="2" :sm="4" :md="4">
						<div class="grid-content bg-purple-light text"></div>
					</el-col>
					<el-col :xs="14" :sm="12" :md="12">
						<div class="grid-content bg-purple-light text-right   text">---&nbsp;&nbsp;<span
								class="text-left">{{ detail.apuName }}</span></div>
					</el-col>
				</el-row>
				<el-row :gutter="10">
					<el-col :xs="6" :sm="4" :md="4">
						<div class="grid-content bg-purple-light  text zui-text-center">
							<p class="text-justify  ">{{ $t('airplaneDetail.label15') }}</p>
						</div>
					</el-col>
					<el-col :xs="2" :sm="4" :md="4">
						<div class="grid-content bg-purple-light text"></div>
					</el-col>
					<el-col :xs="2" :sm="4" :md="4">
						<div class="grid-content bg-purple-light text"></div>
					</el-col>
					<el-col :xs="14" :sm="12" :md="12">
						<div class="grid-content bg-purple-light text-right text">
							{{ detail.apuCirculateNum ? '---&nbsp;&nbsp;' : '' }}<span
								class="text-left">{{ detail.apuHours }}{{ $t('airplaneDetail.unit1') }}</span>
						</div>
					</el-col>
				</el-row>
				<el-row :gutter="10">
					<el-col :xs="6" :sm="4" :md="4">
						<div class="grid-content bg-purple-light  text zui-text-center">
							<p class="text-justify  ">{{ $t('airplaneDetail.label17') }}</p>
						</div>
					</el-col>
					<el-col :xs="2" :sm="4" :md="4">
						<div class="grid-content bg-purple-light text"></div>
					</el-col>
					<el-col :xs="2" :sm="4" :md="4">
						<div class="grid-content bg-purple-light text"></div>
					</el-col>
					<el-col :xs="14" :sm="12" :md="12">
						<div class="grid-content bg-purple-light text-right text">
							---&nbsp;&nbsp;<span
								class="text-left">{{ detail.apuCirculateNum ? detail.apuCirculateNum : '-&nbsp;' }}{{ $t('airplaneDetail.unit2') }}</span></div>
					</el-col>
				</el-row>
			</div>
		</div>
		<div class="zanwei"></div>
		<!-- 分割线 -->
		<div v-if="detail.advantage" class="top_tap flex_row">亮点/Highlight</div>
		<div v-if="detail.advantage" class="light zui-flex zui-flex-column content zui-align-center"
			v-html="detail.advantage"></div>
		<!-- VR -->
		<div v-if="detail.planevrs" class="light content ">
			<div id="viewer"></div>
		</div>

		<div v-if="detail.planvr" class="light zui-flex zui-flex-column content zui-align-center">
			<iframe :src="detail.planvr" style="width: 100%;" width="" :height="bannerHeight"></iframe>
		</div>
		<div style="min-height: 60px;text-align: center; padding: 25px"
			class="zui-flex content zui-flex-column zui-align-center" v-html="detail.intro"></div>
	</div>
</template>
<script>
	// import htmlToPdf from '../util/htmlToPdf.js'
	import {
		Loading
	} from 'element-ui';
	import $config from "../api/config.js"
	import {
		getExportPdf,
		getAirEngines
	} from '../api/api';
	// import pdf from 'vue-pdf';
	import {
		Viewer
	} from 'photo-sphere-viewer';
	import {
		mapState
	} from "vuex";
	import 'photo-sphere-viewer/dist/photo-sphere-viewer.css';
	export default {
		components: {
			// pdf
		},
		data() {
			return {
				viewer: '',
				isPhone: false,
				pdfPath: false,
				pdf:'导出PDF',
				name: '湾流G20',
				detail: {},
				// 图片父容器高度
				bannerHeight: 2000,
				// 浏览器宽度
				screenWidth: 0,
				htmlPdfTitle: '页面导出PDF文件名',
				isShow: true,
				//发动机参数列表
				enginesList:[],
				searchQuery:null
			};
		},
		watch:{
			language(){
					this.$nextTick(()=>{
						this.pdf=this.$t('airplaneDetail.mesg2')
					})
			}
		},
		filters: {
			swiperImg: function(value) {
				// console.log(value);
				if ((value.search("http") != -1) && (value.search("https") != -1)) {
					return value
				} else {
					return $config.apiUrl + '/' + value
				}
			}
		},
		computed: {
			...mapState(["isLogin","language"]),
		},
		created: function() {
	
			if (/Android|webOS|iPhone|iPad|BlackBerry/i.test(navigator.userAgent)) {
				this.isPhone = true
			}
			// console.log(this.$route.params);
			let params = this.$route.params;
			if (!params.planeInfo) {
				this.$router.push({
					name: 'hangar'
				});
			}
		
			this.searchQuery=params.oquery
			this.detail = params.planeInfo;
			//console.log(this.searchQuery)
			this.htmlPdfTitle = params.planeInfo.title;
			this.getEngines()
		},
		methods: {
			_back(){
				if(this.searchQuery){
					if(this.searchQuery.flag=='params'){
						this.$router.push({
							name: 'search',
							params: { search: this.searchQuery }
						});
					}else{
						
					this.$router.go(-1)
				
					}
				}else{
			
				this.$router.go(-1)
			
				}
			},
			getEngines(){
				let id=this.detail.id
			getAirEngines({id:id}).then(res=>{
				let data=res.result.records
				this.enginesList=data
			})
			},
			getVr() {
				//VR
				this.viewer = new Viewer({
					container: document.querySelector('#viewer'),
					// panorama: {
					// 	left: require('../assets/image/demo/1.jpg'),
					// 	front: require('../assets/image/demo/1.jpg'),
					// 	right: require('../assets/image/demo/1.jpg'),
					// 	back: require('../assets/image/demo/1.jpg'),
					// 	top: require('../assets/image/demo/1.jpg'),
					// 	bottom: require('../assets/image/demo/1.jpg'),
					// },
					// panorama: [
					// 	require('../assets/image/demo/1.jpg'),
					// 	require('../assets/image/demo/1.jpg'),
					// 	require('../assets/image/demo/1.jpg'),
					// 	require('../assets/image/demo/1.jpg'),
					// 	require('../assets/image/demo/1.jpg'),
					// 	require('../assets/image/demo/1.jpg'),
					// ],
					panorama: this.$apiUrl + '/' + this.detail.planevrs,
					size: {
						width: '100%',
						height: '50vh'
					}
				});
			},
			setSize: function() {
				// 通过浏览器宽度(图片宽度)计算高度
				// 通过浏览器宽度(图片宽度)计算高度
				this.bannerHeight = (576 / 1920) * this.screenWidth;
				this.centerHeight = (1080 / 1920) * this.screenWidth;
				if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
					this.bannerHeight = this.bannerHeight + 100;
				}
			},
			async getPdfEvn() {
				let loadingInstance = Loading.service({
					text: '使用Chrome或者edge浏览器,导出效果更佳。正在导出~~'
				});
				this.pdf = this.$t('airplaneDetail.mesg1');

				// this.getPdf();
				let pdf_id = this.detail.id;
				await getExportPdf({
					id: pdf_id
				}).then(res => {
					if (!res || res.size === 0) {
						this.$message({
							message: this.$t('airplaneDetail.mesg'),
							showClose: true,
							type: 'error',
							customClass: 'hahaha',
							duration: 2000,
							offset: 20
						});
						loadingInstance.close();
						return
					}
					if (typeof window.navigator.msSaveBlob !== 'undefined') {
						window.navigator.msSaveBlob(new Blob([res]), this.detail.title + '.pdf')
						// console.log(12)
					} else {
						let url = window.URL.createObjectURL(new Blob([res]))
						let link = document.createElement('a')
						link.style.display = 'none'
						link.href = url
						link.setAttribute('download', this.detail.title + '.pdf')
						document.body.appendChild(link)
						link.click()
						document.body.removeChild(link) //下载完成移除元素
						window.URL.revokeObjectURL(url) //释放掉blob对象
						this.pdf = this.$t('airplaneDetail.mesg2')
						loadingInstance.close();
					}
					// this.isShow = !this.isShow;
				});
				// 导出PDF
				// htmlToPdf.downloadPDF(document.querySelector('#pdfDom'),this.htmlPdfTitle);
			}
		},
		mounted() {
			// 首次加载时,需要调用一次
			this.screenWidth = window.innerWidth;
			// 窗口大小发生改变时,调用一次
			this.setSize();
			// 窗口大小发生改变时,调用一次
			window.onresize = () => {
				this.screenWidth = window.innerWidth;
				this.setSize();
			};
			window.scroll(0, 0)
			this.getVr();
		},
		destroyed() {
			window.scroll(0, 0)

		}
	};
</script>
<style lang="scss" scoped>
	@import '../assets/scss/config.scss';

	.log{
		padding-right: 12px;
		// font-weight: bold;
		color: #dbb96e;
		opacity: 0;
		-webkit-user-select: none;
		cursor: default;
	}
	.el-button{
		flex-basis: 0% ;
	}
	.btnblock{
		width: 8.333333333%;
		position: absolute;
		// height: 100px;
		right: 0;
		display: flex;
		justify-content: center;
	}
	.btnCenter{
		height: 26px;
		width: 40px;
		display: flex;
		justify-content: center;
	}
	
	.back {
		padding: 4px 8px;
		font-size: 10px;
		right:5%;
		top:20%;
		border-radius: 4px;
	}
	.important-center {
		text-align: center !important;
	}

	.text-justify {
		min-width: 60px;
		width: calc(100% - 75%);
		text-align-last: justify;
		white-space: nowrap;

	}

	.fixed_width {
		width: 100px;
		text-align: right;
		margin-right: 80px !important;
	}

	.text-center {
		margin: 0 auto;
		//width: 50%;
		text-align: center;
	}

	.text-left {
		text-align: left;
		// width: calc(100% - 20%);
		margin: 0 auto;
		display: inline-block;

	}

	.text-right {
		text-align: right;
		margin: 0 auto;
	}

	.top_tap {
		color: $colorG;
		font-size: $fontK;
		width: 100%;
		height: 43px;
		background: url('../assets/image/17.jpg') center;
	}

	.light {
		min-height: 60px;
		text-align: start;
		padding: 25px;
		font-size: 18px;
		white-space: pre-wrap;
		line-height: 38px;
		font-size: 16px;
	}


	.jinBtn1 {
		// height: 26px;
		padding: 4px 0;
		width: 60px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 4px;
		background-color: #000000;
		color: #DBB96E;
		margin: 0 auto 15px;
		cursor: pointer;

		&:hover {
			background-color: #DBB96E;
			color: #000000;
		}
	}

	.airplane_page {
		// height: 856px;
		display: flex;
		flex-flow: column;
		align-items: center;
		width: 100%;

		img {
			width: 100%;
			height: 100%;
		}

		.el-row {
			margin-bottom: 12px;
		}

		.el-col {
			border-radius: 4px;
		}

		.title {
			font-size: 16px;
			font-weight: bold;
			font-family: Microsoft YaHei;
		}

		.text {
			font-size: 13px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #000000;
		}

		.grid-content {
			border-radius: 4px;
			min-height: 14px;
		}

		.xvxv {
			width: 100%;
			height: 1px;
			margin: 10px auto;
			border-bottom: 1px dashed #858585;
		}

		.box {
			width: 60%;
		}

		.pdf {
			width: 30px;
			height: 30px;
			margin-bottom: 25px;
		}

	}

	//飞机宽高
	/deep/ .plane-img {
		padding-top: 60%;
		width: 100%;
		position: relative;

		img {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
		}
	}

	/deep/ .content {
		width: 60%;
		margin: auto;

		p {
			display: inline-block;
			width: 100%;
			img {
				max-width: 100% !important;
				height: auto;
			}
			video{
				max-width: 100% !important;
				height: auto;
			}
		}
	}
	

	// 手机适配
	@media screen and (max-width: 1024px) {
		.text-center {
			text-align: right;
		}

		.fixed_width {
			width: 100%;
		}

		.text {
			text-align: right;
		}

		.text-justify {
			margin: 0;
		}

		/deep/ .box {
			width: 100% !important;
			padding: 0 10px;
			box-sizing: border-box;
		}

		.pdf {
			width: 20px;
			height: 20px;
		}

		/deep/ .content {

			width: 100%;

			margin: auto;

			p {

				img {

					width: 100% !important;

				}

			}

		}
	}
</style>
